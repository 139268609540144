import React from 'react';

// Bootstrap Components
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Educacion from '../../../../assets/images/education/image2.png';
import fundamentos from '../../../../assets/pdf/fundamentos.pdf';

const HeroSection = () => {
  return (
    <section className="hero-education" id="hero">
      <Container fluid="lg">
        <Row className="content">
          <Col className="hero-education__header">
            <div className="hero-education__header-image">
              <img
                alt="Imagen de una charla realizada por Carolina Ackermann"
                src={Educacion}
                className=" image"
              />
            </div>
            <h1 className="hero-education__header-text">
              Encuentra la propuesta educativa que mejor se adapte a tu grupo de
              amigas, institución, colectivo…
            </h1>
          </Col>
          <Col className="hero-education__def">
            <p className="hero-education__def-title">EDUCACIÓN EN PERI/MENOPAUSIA</p>

            <div className="hero-education__def-text">
              <p className="hero-education__def-text--left">
              La educación peri/menopáusica es una práctica pedagógica con perspectiva de género y Derechos Humanos, que acompaña a las mujeres y a la sociedad en la comprensión y resignificación de esta etapa, superando la visión exclusivamente médica del proceso. 
              </p>
              <p className="hero-education__def-text--right">
              Desde la Asociación de Cultura Menstrual, La Vida en Rojo, brindo educación sobre peri/menopausia para ofrecer a las mujeres información precisa y herramientas de autocuidado, acompañando a transitar esta etapa de cambios de forma saludable y consciente.  
              </p>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              href={fundamentos}
              className="main-btn"
            >
              Lee los fundamentos en educación peri/menopáusica
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
