import { useEffect, useRef, useState } from "react";


export function usePopup () {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [hasPopupOpened, setHasPopupOpened] = useState(false);
  
    const triggerRef = useRef(null);
  
    const openPopup = () => {
      setIsPopupOpen(true);
      setHasPopupOpened(true);
    };
  
    const closePopup = () => {
      setIsPopupOpen(false);
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const triggerPosition = triggerRef.current.getBoundingClientRect().top;
        if (triggerPosition > 0 && !hasPopupOpened) {
          openPopup();
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [hasPopupOpened]);

    return {
      isPopupOpen,
      hasPopupOpened,
      triggerRef,
      openPopup,
      closePopup
    }
}