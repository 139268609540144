import React, { useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import Alert from 'react-bootstrap/esm/Alert';
import Modal from 'react-modal';
import './popupcampaign.css';

import CarolPhoto from '../../assets/images/popup/photo.png';

Modal.setAppElement('#root');

const PopUpCampaign = ({ isOpen, closeModal }) => {

   return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="popupcampaign image"
    >
      <Col className="popupcampaign__infobox">
        
        <button onClick={closeModal} className="link-button">
          <i className="bi bi-x-circle-fill"></i>
        </button>
        <div className='popupcampaign__infobox--content'>
          <img src={CarolPhoto} alt="Agendas" />
          <h1>Descubre las Tarjetas "Vívete"</h1>
          <h5>Tarjetas</h5>
          <h3>VÍVETE</h3>
          <h4>Mujer Sin Reglas</h4>
          <p>Estas bellas tarjetas te invitan a la autoescucha y al diálogo con otras mujeres promoviendo el autocuidado y una vida plena.</p>
          <p>Son un recurso tanto personal como colectivo diseñado para acompañarte en la experiencia de vivir sin reglas.</p>
          <p style={{ fontWeight: 'bold', marginTop: '2rem' }}>¡Excríbeme para saber más!</p>
          <p className='xsmall' style={{ fontWeight: 'bold' }}>carolina.mujersinreglas@gmail.com</p>
        
        </div>
          
      </Col>
    </Modal>
  );
};

export default PopUpCampaign;
